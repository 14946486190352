import styled, { keyframes } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { grayscale, white } from '../../styles/colors'

type buttonProps = {
  fixe: boolean;
}

const fixedHeader = keyframes`
  from {
    top:-60px;
  }
  to {
    top:0;
  }
`
export const Container = styled.header`
  padding: 22px 0;
  left: 0;
  position: fixed;
  min-height: 147px;
  right: 0;
  top: 0;
  z-index: 2;
  background: ${white};

  @media (min-width: ${breakpoints.md}) {
    min-height: 179px;
  }
  @media (min-width: ${breakpoints.md}) {
    min-height: 112px;
  }

  &.drop-menu {
    animation-name: ${fixedHeader};
    animation-duration: .5s;
    align-items: center;
    background: ${white};
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    min-height: 147px;
    position: fixed;
    z-index: 10;
    padding: 18px 0;

    @media (min-width: ${breakpoints.md}) {
      min-height: 184px;
    }
    @media (min-width: ${breakpoints.lg}) {
      min-height: 64px;
    }
  }

  .logo {
    margin-right: 32px;
    display: block;

    @media(min-width: ${breakpoints.lg}) {
      margin-right: 12px;
      margin-left: -10px;
    }

    @media(min-width: ${breakpoints.xl}) {
      margin-right: 32px;
    }

    svg {
      width: 85px;
      transform: translateY(-2px);

      @media (min-width: ${breakpoints.xl}) {
        width: 120px;
      }
    }
  }
  .profile span {
    color: ${grayscale[400]}
  }

  .header-items {
    min-height: 67px;
  }
`
export const Item = styled.li`
  white-space: nowrap;

  & + li {
    margin-left: 24px;

    @media(min-width: ${breakpoints.lg}) {
      margin-left: 12px;
    }

    @media(min-width: ${breakpoints.xl}) {
      margin-left: 24px;
    }
  }

  .link {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600px;
    color: #161616;

    @media(min-width: ${breakpoints.lg}) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`
export const Button = styled.a`
  width: 75px;
  height: ${(props: buttonProps) => props.fixe ? '28px' : '48px'};
  background: #FF7A00;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: ${breakpoints.lg}) {
    width: 100px;
  }

  @media(min-width: ${breakpoints.xl}) {
    width: 128px;
  }

  &:focus, &:hover {
    outline: none;
    color: #fff;
    text-decoration: none;
    opacity: 0.9;
  }
`
