import { createGlobalStyle } from 'styled-components'
import reset from './reset'
import { white, gray, grayscale, orange, generateColorClasses } from './colors'
import { generateFontSizeClasses, generateFontWeightClasses, generateLineHeightClasses } from './fonts'
import { generateBorderRadiusClasses } from './radius'
import breakpoints from './breakpoints'
import 'react-multi-carousel/lib/styles.css'
// import '../sass/global.scss'
import '../assets/sass/global.scss'

const globalStyle = createGlobalStyle`
  ${reset}

  .fill-white {
    fill: ${white};
  };

  .gatsby-image-wrapper {
    width: 100%;
  }

  main {
    margin-top: 147px;

    @media(min-width: ${breakpoints.md}) {
      margin-top: 159px;
    }
    @media(min-width: ${breakpoints.lg}) {
      margin-top: 112px;
    }
  }

  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(gray, 'gray')}
  ${generateColorClasses(grayscale, 'grayscale')}
  ${generateFontWeightClasses()}
  ${generateFontSizeClasses()}
  ${generateLineHeightClasses()}
  ${generateBorderRadiusClasses()}
`

export default globalStyle
