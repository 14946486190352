import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import ScrollContainer from 'react-indiana-drag-scroll'

// Hooks
import useScroll from '../../hooks/useScroll'
import useWidth from '../../hooks/useWidth'
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'

// Components
import ScrollTo from '../ScrollTo'
import logo from '../../assets/images/logo.json'

// Style
import { Container, Item, Button } from './style'

const WIDTH_LG = 1024
const WIDTH_MD = 768

const HeaderGoTech = () => {
  const [ isTablet, setIsTablet ] = useState(true)
  const windowWidth = useWidth(300)
  const scrollPositionY: number = useScroll(100)
  const fixedHeader = scrollPositionY > 70
  const [ sendDatalayerEvent ] = useDataLayer()
  const logoContainerRef = useRef(null)

  const width = useWidth(300)

  const scrollValue = windowWidth <= WIDTH_MD ? 143 : 64

  useLayoutEffect(() => {
    setIsTablet(width < WIDTH_LG)
  }, [ width ])

  useEffect(() => {
    const logoContainer = logoContainerRef.current
    const anim = lottie.loadAnimation({
      container: logoContainer as unknown as Element,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: logo,
    })

    return () => {
      anim.destroy()
    }
  }, [])

  return (
    <>
      <Container
        className={`
        d-flex align-items-center
        ${fixedHeader ? 'drop-menu' : 'landscape'}`}
      >
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between header-logo'>
            <div className='d-flex align-items-center'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Logo Inter - Header Carreiras',
                    section_name: 'null',
                    redirect_url: window.location.origin,
                  })
                }}
                href='https://carreiras.inter.co/go-tech'
                className='logo'
                title='Visite o site'
              >
                <div ref={logoContainerRef} aria-label='Inter' />
              </a>

              {
                !isTablet && (
                  <ul className='d-flex'>
                    <Item>
                      <ScrollTo
                        to='#sobre'
                        title='Sobre'
                        styles='link'
                        scrollHeight={scrollValue}
                        elementName='Sobre'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#sobre'
                        elementAction='click scroll'
                      >
                        Sobre
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Jornada'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#jornada'
                        elementAction='click scroll'
                        to='#jornada'
                        title='Jornada'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Jornada
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Área de Atuação'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#area-atuacao'
                        elementAction='click scroll'
                        to='#area-atuacao'
                        title='Área de Atuação'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Área de atuação
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Benefícios'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#beneficios'
                        elementAction='click scroll'
                        to='#beneficios'
                        title='Benefícios'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Benefícios
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Requisitos'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#requisitos'
                        elementAction='click scroll'
                        to='#requisitos'
                        title='Requisitos'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Requisitos
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Etapas'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#etapas'
                        elementAction='click scroll'
                        to='#etapas'
                        title='Etapas'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Etapas
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Depoimentos'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#depoimentos'
                        elementAction='click scroll'
                        to='#depoimentos'
                        title='Depoimentos'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Depoimentos
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='O Inter'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#conheca-inter'
                        elementAction='click scroll'
                        to='#conheca-inter'
                        title='O Inter'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        O Inter
                      </ScrollTo>
                    </Item>

                    <Item>
                      <ScrollTo
                        elementName='Perguntas'
                        redirectUrl='https://carreiras.bancointer.com.br/go-tech/#perguntas'
                        elementAction='click scroll'
                        to='#perguntas'
                        title='Perguntas'
                        styles='link'
                        scrollHeight={scrollValue}
                      >
                        Perguntas
                      </ScrollTo>
                    </Item>
                  </ul>
                )
              }
            </div>

            {!isTablet && (
              <Button
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Cadastre-se',
                    section_name: 'null',
                    redirect_url: 'https://matchbox.digital/estagiointergotech2024-inscricao',
                  })
                }}
                fixe={fixedHeader}
                title='Cadastre-se'
                href='https://matchbox.digital/estagiointergotech2024-inscricao'
                target='blank'
              >
                Cadastre-se
              </Button>
            )}
          </div>

          {
            isTablet && (
              <ScrollContainer>
                <ul className='d-flex align-items-center header-items'>
                  <Item>
                    <ScrollTo
                      to='#sobre'
                      title='Sobre'
                      styles='link'
                      scrollHeight={scrollValue}
                      elementName='Sobre'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#sobre'
                      elementAction='click scroll'
                    >
                      Sobre
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Jornada'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#jornada'
                      elementAction='click scroll'
                      to='#jornada'
                      title='Jornada'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Jornada
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Área de Atuação'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#area-atuacao'
                      elementAction='click scroll'
                      to='#area-atuacao'
                      title='Área de Atuação'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Área de atuação
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Benefícios'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#beneficios'
                      elementAction='click scroll'
                      to='#beneficios'
                      title='Benefícios'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Benefícios
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Requisitos'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#requisitos'
                      elementAction='click scroll'
                      to='#requisitos'
                      title='Requisitos'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Requisitos
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Etapas'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#etapas'
                      elementAction='click scroll'
                      to='#etapas'
                      title='Etapas'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Etapas
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Depoimentos'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#depoimentos'
                      elementAction='click scroll'
                      to='#depoimentos'
                      title='Depoimentos'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Depoimentos
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='O Inter'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#conheca-inter'
                      elementAction='click scroll'
                      to='#conheca-inter'
                      title='O Inter'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      O Inter
                    </ScrollTo>
                  </Item>

                  <Item>
                    <ScrollTo
                      elementName='Perguntas'
                      redirectUrl='https://carreiras.bancointer.com.br/go-tech/#perguntas'
                      elementAction='click scroll'
                      to='#perguntas'
                      title='Perguntas'
                      styles='link'
                      scrollHeight={scrollValue}
                    >
                      Perguntas
                    </ScrollTo>
                  </Item>
                </ul>
              </ScrollContainer>
            )
          }
        </div>
      </Container>
    </>
  )
}

export default HeaderGoTech
