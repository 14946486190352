import React from 'react'

// Hooks
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'
interface IScrollTo {
  to: string;
  children: React.ReactNode;
  styles?: string;
  title: string;
  scrollHeight: number;
  elementAction: 'click button' | 'click scroll';
  elementName: string;
  redirectUrl: string;
}

const ScrollTo: React.FC<IScrollTo> = ({ to, children, styles, title, scrollHeight, elementAction, elementName, redirectUrl }: IScrollTo): React.ReactElement => {
  const [ sendDatalayerEvent ] = useDataLayer()

  function scrollTo (evt: React.MouseEvent) {
    evt.preventDefault()
    const target = evt.target as HTMLAnchorElement
    const topElement = document.getElementById(target.hash.replace('#', ''))
    if (!topElement) { return }
    const offsetTopElem = topElement.getBoundingClientRect()
    if (typeof window !== 'undefined') {
      const top = offsetTopElem.top + window.scrollY - scrollHeight

      window.scroll({
        top,
        behavior: 'smooth',
      })
    }
    sendDatalayerEvent({
      element_action: elementAction,
      element_name: elementName,
      c_page: window.location.href,
      redirect_url: redirectUrl,
    })
  }

  return (
    <a
      href={to}
      onClick={
        (event: React.MouseEvent) => scrollTo(event)
      }
      className={styles}
      title={title}
    >
      {children}
    </a>
  )
}

export default ScrollTo
