import React from "react"
import { Helmet } from "react-helmet"

interface IQuery {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
    };
  };
}

interface ISeo {
  title: string;
  description: string;
  meta?: [];
}

const tagManagerScript = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
  "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
  "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
  "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
  "})(window,document,'script','dataLayer','GTM-NX2BF7K');"

const SEO: React.FC<ISeo> = ({ title, description, meta }: ISeo) => {

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "pt-BR"
        }}
        title={title}
        titleTemplate={title}
        meta={[
          {
            name: "description",
            content: description
          },
          {
            property: "og:title",
            content: title
          },
          {
            property: "og:description",
            content: description
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            name: "twitter:card",
            content: "summary"
          },
          {
            name: "twitter:creator",
            content: "@bancointer"
          },
          {
            name: "twitter:title",
            content: title
          },
          {
            name: "twitter:description",
            content: description
          }
        ].concat(meta || [])}
      >
        <script type="text/javascript">
          {tagManagerScript}
        </script>
        <script
          type="text/javascript"
          src="https://boards-api.greenhouse.io/v1/example/method/url?callback=inter">
        </script>
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NX2BF7K"
          height="0"
          width="0"
          style={{
            display: "none",
            visibility: "hidden"
          }}
        />
      </noscript>
    </>
  )
}

SEO.defaultProps = {
  meta: []
}

export default SEO
