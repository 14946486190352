import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import lottie from 'lottie-web'

// Hooks
import useScroll from '../../hooks/useScroll'
import useWidth from '../../hooks/useWidth'
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'

// Components
import ScrollTo from '../ScrollTo'
import logo from '../../assets/images/logo.json'

// Style
import { Container, Item, Button } from './style'

const WIDTH_LG = 1024
const WIDTH_MD = 768

type headerProps = {
  isHome?: boolean;
}

const Header = ({ isHome }: headerProps) => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ isTablet, setIsTablet ] = useState(true)
  const windowWidth = useWidth(300)
  const scrollPositionY: number = useScroll(100)
  const fixedHeader = scrollPositionY > 70
  const [ sendDatalayerEvent ] = useDataLayer()
  const logoContainerRef = useRef(null)

  const width = useWidth(300)

  const scrollValue = windowWidth <= WIDTH_MD ? 143 : 64

  useLayoutEffect(() => {
    setIsTablet(width < WIDTH_LG)
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  useEffect(() => {
    const logoContainer = logoContainerRef.current
    const anim = lottie.loadAnimation({
      container: logoContainer as unknown as Element,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: logo,
    })

    return () => {
      anim.destroy()
    }
  }, [])

  return (
    <>
      <Container
        className={`
        d-flex align-items-center
        ${fixedHeader ? 'drop-menu' : 'landscape'}`}
      >
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between header-logo'>
            <div className='d-flex align-items-center'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Logo Inter - Header Carreiras',
                    section_name: 'null',
                    redirect_url: window.location.origin,
                  })
                }}
                href='/'
                className='logo'
                title='Visite o site'
              >
                <div ref={logoContainerRef} aria-label='Inter' />
              </a>

              {
                !isTablet && (
                  <ul className='d-flex'>
                    <Item>
                      {
                        isHome ? (
                          <ScrollTo
                            to='#beneficios'
                            title='Benefícios'
                            styles='link'
                            scrollHeight={scrollValue}
                            elementName='Benefícios - Header Carreiras'
                            redirectUrl='https://carreiras.bancointer.com.br/#beneficios'
                            elementAction='click scroll'
                          >
                            Benefícios
                          </ScrollTo>
                        ) : (
                          <a
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'null',
                                element_action: 'click button',
                                element_name: 'Benefícios - Header Carreiras',
                                section_name: 'null',
                                redirect_url: 'https://carreiras.bancointer.com.br/#beneficios',
                              })
                            }}
                            href='/#beneficios'
                            title='Benefícios'
                            className='link'
                          >
                            Benefícios
                          </a>
                        )
                      }
                    </Item>

                    <Item>
                      {
                        isHome ? (
                          <ScrollTo
                            elementName='Depoimentos - Header Carreiras'
                            redirectUrl='https://carreiras.bancointer.com.br/#depoimentos'
                            elementAction='click scroll'
                            to='#depoimentos'
                            title='Depoimentos'
                            styles='link'
                            scrollHeight={scrollValue}
                          >
                            Depoimentos
                          </ScrollTo>
                        ) : (
                          <a
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'null',
                                element_action: 'click button',
                                element_name: 'Depoimentos - Header Carreiras',
                                section_name: 'null',
                                redirect_url: 'https://carreiras.bancointer.com.br/#depoimentos',
                              })
                            }}
                            href='/#depoimentos'
                            title='Depoimentos'
                            className='link'
                          >
                            Depoimentos
                          </a>
                        )
                      }
                    </Item>

                    <Item>
                      {
                        isHome ? (
                          <ScrollTo
                            elementName='Área de Atuação - Header Carreiras'
                            redirectUrl='https://carreiras.bancointer.com.br/#area-de-atuacao'
                            elementAction='click scroll'
                            to='#area-de-atuacao'
                            title='Área de Atuação'
                            styles='link'
                            scrollHeight={scrollValue}
                          >
                            Área de Atuação
                          </ScrollTo>
                        ) : (
                          <a
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'null',
                                element_action: 'click button',
                                element_name: 'Área de Atuação - Header Carreiras',
                                section_name: 'null',
                                redirect_url: 'https://carreiras.bancointer.com.br/#area-de-atuacao',
                              })
                            }}
                            href='/#area-de-atuacao'
                            title='Área de Atuação'
                            className='link'
                          >
                            Área de Atuação
                          </a>
                        )
                      }
                    </Item>
                  </ul>
                )
              }
            </div>
            {!isMobile && (
              <Button
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: 'Vagas - Header Carreiras',
                    section_name: 'null',
                    redirect_url: 'https://carreiras.bancointer.com.br/vagas',
                  })
                }}
                fixe={fixedHeader}
                title='Vagas'
                href='/carreiras'
              >
                Vagas
              </Button>
            )}
          </div>

          {
            isTablet && (
              <ScrollContainer>
                <ul className='d-flex align-items-center header-items'>
                  <Item>
                    {
                      isHome ? (
                        <ScrollTo
                          elementName='Benefícios - Header Carreiras'
                          redirectUrl='https://carreiras.bancointer.com.br/#beneficios'
                          elementAction='click scroll'
                          to='#beneficios'
                          title='Benefícios'
                          styles='link'
                          scrollHeight={scrollValue}
                        >
                          Benefícios
                        </ScrollTo>
                      ) : (
                        <a
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'null',
                              element_action: 'click button',
                              element_name: 'Benefícios - Header Carreiras',
                              section_name: 'null',
                              redirect_url: 'https://carreiras.bancointer.com.br/#beneficios',
                            })
                          }}
                          href='/#beneficios'
                          title='Benefícios'
                          className='link'
                        >
                          Benefícios
                        </a>
                      )
                    }
                  </Item>

                  <Item>
                    {
                      isHome ? (
                        <ScrollTo
                          elementName='Depoimentos - Header Carreiras'
                          redirectUrl='https://carreiras.bancointer.com.br/#depoimentos'
                          elementAction='click scroll'
                          to='#depoimentos'
                          title='Depoimentos'
                          styles='link'
                          scrollHeight={scrollValue}
                        >
                          Depoimentos
                        </ScrollTo>
                      ) : (
                        <a
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'null',
                              element_action: 'click button',
                              element_name: 'Depoimentos - Header Carreiras',
                              section_name: 'null',
                              redirect_url: 'https://carreiras.bancointer.com.br/#depoimentos',
                            })
                          }}
                          href='/#depoimentos'
                          title='Depoimentos'
                          className='link'
                        >
                          Depoimentos
                        </a>
                      )
                    }
                  </Item>

                  <Item>
                    {
                      isHome ? (
                        <ScrollTo
                          elementName='Área de Atuação - Header Carreiras'
                          redirectUrl='https://carreiras.bancointer.com.br/#area-de-atuacao'
                          elementAction='click scroll'
                          to='#area-de-atuacao'
                          title='Área de Atuação'
                          styles='link'
                          scrollHeight={scrollValue}
                        >
                          Área de Atuação
                        </ScrollTo>
                      ) : (
                        <a
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'null',
                              element_action: 'click button',
                              element_name: 'Área de Atuação - Header Carreiras',
                              section_name: 'null',
                              redirect_url: 'https://carreiras.bancointer.com.br/#area-de-atuacao',
                            })
                          }}
                          href='/#area-de-atuacao'
                          title='Área de Atuação'
                          className='link'
                        >
                          Área de Atuação
                        </a>
                      )
                    }
                  </Item>
                </ul>
              </ScrollContainer>
            )
          }
        </div>
      </Container>
    </>
  )
}

export default Header
